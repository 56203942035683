import Vue from "vue"
import VueRouter from "vue-router"
import HomeView from "../views/HomeView.vue"
import store from "../store"

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "HomeView",
		component: HomeView,
	},
	{
		path: "/collection",
		name: "CollectionView",
		component: () => import("../views/CollectionView.vue"),
	},
	{
		path: "/login",
		name: "LoginView",
		component: () => import("../views/auth/LoginView.vue"),
	},
	{
		path: "/guestbook",
		name: "GuestBook",
		component: () => import("../views/Guestbook.vue"),
	},
	{
		path: "/events",
		name: "Events",
		component: () => import("../views/EventsView.vue"),
	},
	{
		path: "/events/:id",
		name: "EventView",
		component: () => import("../views/EventView.vue"),
	},
	{
		path: "/users",
		name: "Users",
		component: () => import("../views/UsersView.vue"),
	},
	{
		path: "/provenance",
		name: "ProvenanceDatabase",
		component: () => import("../views/Provenance.vue"),
	},
	{
		path: "/shop",
		name: "StoreView",
		component: () => import("../views/StoreView.vue"),
	},
	{
		path: "/neveragain",
		name: "Neveragain",
		component: () => import("../views/Neveragain.vue"),
	},
	{
		path: "*",
		name: "NotFound",
		component: () => import("../views/NotFound.vue"),
	},
]

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach(async (to, from, next) => {
	try {
		await store.dispatch("getUser")
		if (!store.getters.isAuthenticated && to.name !== "LoginView") {
			next({name: "LoginView"})
		} else if (store.getters.isAuthenticated && to.name === "LoginView") {
			next({name: "HomeView"})
		} else {
			next()
		}
	} catch (error) {
		if (to.name !== "LoginView") {
			next({name: "LoginView"})
		} else {
			next()
		}
	}
})

export default router
