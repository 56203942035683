import Vue from "vue"
import Vuex from "vuex"
import router from "./router"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: {},
		isAuthenticated: false, // Explicit authentication state
	},
	actions: {
		getUser({commit}) {
			return hubapi
				.get("/user")
				.then((response) => {
					commit("setUser", response.data)
					commit("setAuthentication", true)
				})
				.catch(() => {
					commit("setUser", {})
					commit("setAuthentication", false)
				})
		},
	},
	getters: {
		currentUser: (state) => state.user,
		isAuthenticated: (state) => state.isAuthenticated,
	},
	mutations: {
		setUser(state, user) {
			state.user = user
		},
		setAuthentication(state, isAuthenticated) {
			state.isAuthenticated = isAuthenticated
		},
	},
})
