<template>
	<div class="custom-container flex-center flex-column pa-md-10 pa-5">
		<div class="w-100 flex-center flex-column">
			<div class="w-100 px-3 flex-center flex-column">
				<h2 class="w-100 white--text font-weight-medium ">
					Welcome, {{ currentUser.name.slice(0, currentUser.name.indexOf(" ")) }}
				</h2>
				<span class="w-100 white--text font-weight-light mb-7" style="opacity: .7; font-size: 14px">
					This is your admin console. Manage the collection and website content from here.
				</span>

				<v-alert color="#0f0f0f" close-icon="mdi-close" class="w-100 closed-disclaimer pa-0" dark>
					<div class="px-3 pt-3">
						Homepage disclaimer
					</div>
					<div class="w-100 flex-center flex-column py-4" v-if="activeNotification">
						<span class="w-100 white--text px-4 mb-3" style="opacity: 0.5">
							EN: {{ activeNotification.content_en }}
						</span>
						<span class="w-100 white--text px-4" style="opacity: 0.5">
							HR: {{ activeNotification.content_hr }}
						</span>
						<v-divider class="w-100 my-4"></v-divider>

						<v-btn plain color="error" small @click="deleteNotification">
							Remove
						</v-btn>
					</div>

					<div class="w-100 flex-center flex-column py-4" v-else>
						<span class="w-100 white--text px-4" style="opacity: 0.5" v-if="!adding">
							Display a message on the homepage
						</span>

						<div class="w-100 flex-center flex-column px-4" v-else>
							<v-text-field dense filled color="#ffffff50" hide-details v-model="newNotification.content_en"
								label="English" class="w-100 mb-4"></v-text-field>
							<v-text-field dense filled color="#ffffff50" hide-details v-model="newNotification.content_hr"
								label="Croatian" class="w-100 mb-4"></v-text-field>
						</div>
						<v-divider class="w-100 my-4"></v-divider>

						<div class="w-100 flex-center">
							<v-btn plain color="#a38e6f" small @click="adding = !adding">
								<span v-if="!adding">Add Disclaimer</span>
								<span v-else class="red--text">Cancel</span>
							</v-btn>
							<v-btn plain color="success" small @click="addNotification" v-if="adding">
								Confirm
							</v-btn>
						</div>
					</div>
				</v-alert>
			</div>


			<v-row class="w-100 ma-0" v-if="stats">
				<v-col cols="12" sm="6" md="4">
					<v-card class="w-100" dark color="#121212">
						<v-card-title>
							<span class="font-weight-regular" style="font-size: 16px">Collection</span>
						</v-card-title>
						<v-divider></v-divider>
						<v-card-text>
							You have <span class="font-weight-bold"> {{ calcArtworks }} </span> artworks by <span
								class="font-weight-bold">{{ calcArtists }}</span> artists in your collection <br> <br>


						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn plain small color="#a38e6f" href="/collection">
								Manage
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="4">
					<v-card class="w-100" dark color="#121212">
						<v-card-title>
							<span class="font-weight-regular" style="font-size: 16px">Users</span>
						</v-card-title>
						<v-divider></v-divider>
						<v-card-text class="flex-grow-1">
							- &nbsp; <span class="font-weight-bold"> {{ stats.admins }} </span> administrators <br>
							- &nbsp; <span class="font-weight-bold"> {{ stats.provenanceAccounts }} </span> provenance
							database accounts
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn plain small color="#f2f2f2" href="/provenance">
								Provenance
							</v-btn>

							<v-btn plain small color="#a38e6f" href="/users">
								Admins
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="4">
					<v-card class="w-100" dark color="#121212">
						<v-card-title>
							<span class="font-weight-regular" style="font-size: 16px">Pending</span>
						</v-card-title>
						<v-divider></v-divider>
						<v-card-text class="flex-grow-1">
							- &nbsp; <span class="font-weight-bold"> {{ stats.accessRequests }} </span> new access requests
							<br>
							- &nbsp; <span class="font-weight-bold"> {{ stats.guestbookEntries }} </span> new guestbook
							entries
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn plain small color="#f2f2f2" href="/provenance">
								Database
							</v-btn>
							<v-btn plain small color="#a38e6f" href="/guestbook">
								Guestbook
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
	name: "HomeView",
	data: () => ({
		activeNotification: null,
		newNotification: {
			content_en: null,
			content_hr: null,
		},
		adding: false,
		stats: null
	}),
	mounted() {
		this.init()
	},
	computed: {
		...mapGetters(["currentUser"]),
		calcArtists() {
			return Object.keys(this.stats.artworks).length
		},
		calcArtworks() {
			let artworks = 0

			// stats.artworks is an object with artist names as keys and with values of arrays of artworks
			for(let artist in this.stats.artworks) {
				artworks += this.stats.artworks[artist].length
			}

			return artworks
		}
	},
	methods: {
		init() {
			if(this.currentUser.id) {
				this.getNotification()
				this.getStats()
			}
		},
		getStats() {
			hubapi
				.get("/dashboard/stats")
				.then((response) => {
					if(response.status === 200) {
						this.stats = response.data
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		getNotification() {
			hubapi
				.get("/notification")
				.then((response) => {
					if(response.status === 200) {
						this.activeNotification = response.data
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		addNotification() {
			hubapi
				.post("/notification", this.newNotification)
				.then((response) => {
					if(response.status === 201) {
						this.adding = false
						this.getNotification()
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		deleteNotification() {
			hubapi
				.post("/notification/remove")
				.then((response) => {
					if(response.status === 201) {
						this.getNotification()
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
	},
}
</script>