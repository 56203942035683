import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import axios from "axios"
import {api} from "./.env.js"
import store from "./store.js"

Vue.config.productionTip = false

const VueCookie = require("vue-cookies")
Vue.use(VueCookie)

window.hubapi = axios.create({
	baseURL: api + "/api",
})

hubapi.interceptors.request.use(
	function (config) {
		config.headers["Accept"] = "application/json"
		config.headers["Authorization"] = "Bearer " + VueCookie.get("hub-token")

		return config
	},
	function (error) {
		return Promise.reject(error)
	}
)

new Vue({
	router,
	vuetify,
	store: store,
	render: (h) => h(App),
	data() {
		return {
			isGuest: true,
		}
	},
	created() {
		this.isGuest = this.$cookies.get("hub-token") == null

		let self = this

		hubapi.interceptors.response.use(
			(response) => {
				return response
			},
			(error) => {
				if (error.response.status === 401) {
					self.isGuest = true
					self.$cookies.remove("hub-token")
				} else if (error.response.status === 404) {
					self.$router.replace("/404")
				}
			}
		)
	},
}).$mount("#app")
