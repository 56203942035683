<template>
	<div class="sidebar align-start justify-start flex-column hidden-sm-and-down">
		<div class="d-flex align-center justify-start px-5 pt-5">
			<img src="../assets/logo.svg" alt="The Hub" class="main-logo mr-5" />
			<div class="flex-center flex-column">
				<h2 class="w-100 white--text font-weight-medium">
					The Hub
				</h2>
				<span class="w-100 white--text font-weight-light small-text">
					Admin Console
				</span>
			</div>
		</div>

		<v-alert type="error" class="mt-5 w-100" v-if="error">
			<span class="white--text">
				{{ error }}
			</span>
		</v-alert>

		<v-divider class="w-100 my-6" style="background-color: rgba(255, 255, 255, 0.2);"></v-divider>
		<div class="d-flex align-start justify-start flex-column px-5 pb-7" style="height: 100%">
			<router-link v-for="(item, index) in navigation" :key="index" :to="item.path"
				class="d-flex align-center justify-start mb-6 sidebar-link" style="text-decoration: none">
				<v-icon class="mr-5" color="#FFFFFF">{{ item.icon }}</v-icon>
				<span class="white--text font-weight-light">
					{{ item.title }}
				</span>
			</router-link>

			<v-spacer></v-spacer>

			<div class="d-flex align-center justify-start sidebar-link" style="text-decoration: none" @click="logout()">
				<v-icon class="mr-5" color="error">mdi-logout</v-icon>
				<span class="font-weight-light" style="color: #FF5252;">
					Log out
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SidebarComponent",
	data: () => ({
		error: null,
		navigation: [
			{
				title: "Dashboard",
				icon: "mdi-view-dashboard",
				path: "/",
			},
			{
				title: "Collection",
				icon: "mdi-palette",
				path: "/collection",
			},
			{
				title: "Database",
				icon: "mdi-database",
				path: "/provenance",
			},

			{
				title: "Shop",
				icon: "mdi-shopping",
				path: "/shop",
			},
			{
				title: "Guestbook",
				icon: "mdi-book-open-page-variant",
				path: "/guestbook",
			},
			{
				title: "Events",
				icon: "mdi-calendar",
				path: "/events",
			},
			{
				title: "Users",
				icon: "mdi-account-group",
				path: "/users",
			},
		]
	}),
	methods: {
		logout() {
			hubapi.post("/logout")
				.then((response) => {
					if(response.status === 200 || response.status === 204) { // Assuming 200 OK or 204 No Content for successful logout
						this.$store.commit('setUser', {})
						this.$store.commit('setAuthentication', false)

						this.$cookies.remove("hub-token")

						this.$router.push({name: "LoginView"})
					}
				})
				.catch((err) => {
					this.error = "Failed to log out. Please try again."
				})
		},

	},
}
</script>
<style lang="scss">
.sidebar {
	position: fixed;
	display: flex;
	top: 0;
	left: 0;
	width: 250px;
	height: 100%;
	background-color: #111111;
	z-index: 2;
}

.sidebar-link {
	transition: 0.2s;
	cursor: pointer;

	i {
		transition: 0.2s;
		opacity: 0.8;
	}

	span {
		transition: 0.2s ease;
		opacity: 0.5;
		font-size: 14px;
	}

	&:hover {
		span {
			opacity: 1;
		}

		i {
			opacity: 1;
		}
	}
}

.router-link-exact-active {
	span {
		opacity: 1 !important;
	}

	i {
		opacity: 1 !important;
	}
}
</style>