<template>
	<v-app>
		<v-main>
			<sidebar-component v-if="currentUser?.id && this.$router.currentRoute.name != 'LoginView'"
				class="hidden-sm-and-down"></sidebar-component>
			<div :class="{ 'content-wrap': currentUser?.id && this.$router.currentRoute.name != 'LoginView' }"
				class="w-100">
				<router-view />
			</div>
		</v-main>
	</v-app>
</template>

<script>
import SidebarComponent from "@/components/Sidebar.vue"
import {mapGetters} from 'vuex'
export default {
	name: "App",
	components: {
		SidebarComponent,
	},
	computed: {
		...mapGetters(["currentUser"]),
	},
}
</script>
<style lang="scss">
body,
html {
	padding: 0;
	margin: 0;
	background-color: #212121;
}

.v-main {
	background-color: #212121;
}

.main-logo {
	height: 50px;
}

@media (max-width: 965px) {
	.main-logo {
		height: 40px;
	}

	.content-wrap {
		margin-left: 0;
	}
}

.normal-spacing {
	letter-spacing: normal;
	text-transform: none;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-column {
	flex-direction: column;
}

.w-100 {
	width: 100%;
}

.small-text {
	font-size: 12px;
}

.pointer {
	cursor: pointer;
}

.content-wrap {
	padding-left: 250px;
}

@media (max-width: 965px) {
	.content-wrap {
		padding-left: 0;
	}
}
</style>
